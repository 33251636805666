<template>
  <div class="md-layout md-alignment-top-space-between auth-panel md-elevation-5">
    <div class="md-layout-item md-size-100 login-form">
      <md-card class="md-elevation-0">
        The login with {{provider}} is in progress...  Please Wait...
      </md-card>
    </div>
  </div>
</template>

<script>
import { SOCIAL_LOGIN_SUCCESS } from '@/store/modules/auth/authActions.type';

export default {
  name: 'UserSocialLogin',
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  props: {
    provider: {
      type: String,
      default: null,
    },
    code: {
      type: String,
    },
  },
  mounted() {
    this.login();
  },
  methods: {
    login() {
      const payload = {
        code: this.$route.query.code,
        provider: this.provider,
      };
      this.$store.dispatch(SOCIAL_LOGIN_SUCCESS, payload)
        .then((response) => {
          this.$log.info(SOCIAL_LOGIN_SUCCESS, payload);

          if (response.data.firstLogin) {
            this.$router.push('/user/step1');
          } else {
            this.$router.push('/');
          }

          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(SOCIAL_LOGIN_SUCCESS, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'VALIDATION_ERROR' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common/auth/auth-panel.scss';
</style>
